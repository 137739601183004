import React, {useState} from 'react'
import { useSelector } from 'react-redux';
import ProofReaderConfigure from './ProofReaderConfigure'
import TranslationConfigure from './TranslationConfigure'
import TranslationConfigureFiles from './TranslationConfigureFiles';

const Configure = () => {
    const { user } = useSelector((state) => state.auth);
    const { serviceType, checkMultipleFiles, blobFile, jobWordCount, jobPrice } = useSelector((state) => state.jobPost)
    const [filesPrice, setFilesPrice] = useState(jobPrice);
    const handlePriceFiles = (values) => {
        setFilesPrice(values);
    };

    return (
        <>
            <div className="card2 show">
                <h3>Details:</h3>
                <div className="Details-card-r">

                    <div className="Details-card-box">
                        {checkMultipleFiles ? <p>job name</p>:<p>document name</p>}
                        <h6>{blobFile?.name}</h6>
                    </div>
                    <div className="Details-card-box">
                        <p>word count</p>
                        <h6>{jobWordCount}</h6>
                    </div>
                    <div className="Details-card-box">
                        <p>price</p>
                        <h6><a href="#">{checkMultipleFiles ? filesPrice.toFixed(2) : jobPrice.toFixed(2)} USD</a></h6>
                    </div>

                </div>

                {serviceType === "translation" && checkMultipleFiles ? <TranslationConfigureFiles handlePriceFiles={handlePriceFiles}/> :<TranslationConfigure />}
                {/* <!-- Proofreading --> */}
                {serviceType === "proofreading" && <ProofReaderConfigure />}


            </div>
        </>
    )
}

export default Configure