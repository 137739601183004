import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { checkPromoCode, createJob, createPayment } from '../../../services/api/jobPostApis'
import { useNavigate } from 'react-router-dom'
import ResponsiveStepper from './ResponsiveStepper'
import { setCartCurrency, setJobPrice, setPromoCode, setRewardPoint, setWalletFund } from '../../../redux/reducers/jobPostSlice'
import PromoCodeModal from '../../../components/common/promoCodeModal/PromoCodeModal'
import ReedemRewardpointModal from './ReedemRewardpointModal'
import ReedemWalletModal from './ReedemWalletModal'

const Payment = () => {
    const dispatch = useDispatch()
    const {
        jobWordCount,
        serviceType,
        configProofreading,
        jobPrice,
        selectedSpeedOption,
        rewardPointUsed,
        promoCode,
        walletFundUsed,
        cartCurrency,
    } = useSelector((state) => state.jobPost);
    const { user } = useSelector(state => state.auth)
    const [useReward, setUseReward] = useState(false)
    const [useWallet, setUseWallet] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [openReward, setOpenReward] = useState(false)
    const [usedReward, setUsedReward] = useState(0)
    const [openWallet, setOpenWallet] = useState(false)
    const [usedWallet, setUsedWallet] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [price, setPrice] = useState(jobPrice);
    const [savePrice, setSavePrice] = useState(jobPrice);
   
    
    const payForJob = async () => {
        let bodyData = {
            cartAmount: cartCurrency === "USD" ? price : price * 0.7,
            cartCurrency: cartCurrency,
            returnUrl: `${window.location.origin}`
        }
        let payment = await createPayment(bodyData)
        if (payment) {
            sessionStorage.setItem('transRef', payment?.result?.data?.tran_ref)
            window.location.href = payment?.result?.data?.redirect_url;
            dispatch(setJobPrice({ jobPrice: price }))
        }
    }
     
    const handleJobPrice = () => {
        if(price < 2 || jobPrice < 2 || savePrice < 2){
            toast.error('Total discount types used must not result in total price reaching less than 1 USD')
            return
        }
        if (useReward && useWallet) {
            if (configProofreading.specService === 'Proofreading + Stylesheet Adherence') {
                dispatch(setJobPrice({
                    jobPrice: Number(Number((Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) - usedReward).toFixed(4)) - (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0))
                }))
            }
            dispatch(setRewardPoint({ rewardPointUsed: Number(usedReward) }))
            dispatch(setWalletFund({ walletFundUsed: Number(usedWallet) }))
            setPrice(savePrice - (Number(usedReward) / 20) - Number(usedWallet))
            // dispatch(setWalletFund({ walletFundUsed: 0 }))
            return
        }
        if (useReward && !useWallet) {
            if (configProofreading.specService === 'Proofreading + Stylesheet Adherence') {
                dispatch(setJobPrice({
                    jobPrice: Number(Number((Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) - usedReward).toFixed(4)) - (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0))
                }))
            }
            dispatch(setRewardPoint({ rewardPointUsed: Number(usedReward) }))
            setPrice(savePrice - (Number(usedReward) / 20))
            dispatch(setWalletFund({ walletFundUsed: 0 }))
            return
        }
        if (useWallet && !useReward) {
            if (configProofreading.specService === 'Proofreading + Stylesheet Adherence') {
                dispatch(setJobPrice({
                    jobPrice: Number(Number((Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) - (usedWallet)).toFixed(4)) - (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0))
                }))
            }
            dispatch(setWalletFund({ walletFundUsed: Number(usedWallet) }))
            setPrice(savePrice - Number(usedWallet))
            dispatch(setRewardPoint({ rewardPointUsed: 0 }))
            return
            
        }

        if (!useReward && !useReward) {
            if (configProofreading.specService === 'Proofreading + Stylesheet Adherence') {
                dispatch(setJobPrice({
                    jobPrice: Number(Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) - (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0))
                }))
            }
            dispatch(setRewardPoint({ rewardPointUsed: 0 }))
            dispatch(setWalletFund({ walletFundUsed: 0 }))
            setDiscount(0)
            setPrice(savePrice)
        }
       
    }

    const handlePromoCode = async () => {
        if(price < 2 || jobPrice < 2 || savePrice < 2){
            toast.error('Total discount types used must not result in total price reaching less than 1 USD')
            return
        }
        handleJobPrice()
        let bodyData = {
            promoCode: document.getElementById('promocodeInput').value
        }
        let checkedPromoCode = await checkPromoCode(bodyData)
        if (!checkedPromoCode) {
        }
        else {
            let checkAmmount = checkedPromoCode?.discountType === 'percentage' ? ((jobPrice * (checkedPromoCode?.discountAmount / 100))) : (checkedPromoCode?.discountAmount);
            if(price - checkAmmount < 2 || jobPrice - checkAmmount < 2 || savePrice - checkAmmount < 2  ){
                toast.error('You can not use the promo code if it will make the total price reaching less than 1 USD')
                return;

            }
            dispatch(setPromoCode({ promoCode: { ...checkedPromoCode, discountedAmmount: checkedPromoCode?.discountType === 'percentage' ? ((jobPrice * (checkedPromoCode?.discountAmount / 100))) : (checkedPromoCode?.discountAmount) } }))
            // dispatch(setJobPrice({
            //     jobPrice: checkedPromoCode?.discountType === 'percentage' ? (jobPrice - (jobPrice * (checkedPromoCode?.discountAmount / 100))) : (jobPrice - checkedPromoCode?.discountAmount)
            // }))
            setSavePrice(checkedPromoCode?.discountType === 'percentage' ? (savePrice - (savePrice * (checkedPromoCode?.discountAmount / 100))) : (savePrice - checkedPromoCode?.discountAmount))
            setPrice(
                 checkedPromoCode?.discountType === 'percentage' ? (savePrice - (savePrice * (checkedPromoCode?.discountAmount / 100))) : (savePrice - checkedPromoCode?.discountAmount)
            )
            setDiscount((savePrice * (checkedPromoCode?.discountAmount / 100)).toFixed(2))
        }
    }

    useEffect(() => {
        handleJobPrice()
        console.log(promoCode, rewardPointUsed, walletFundUsed);
        
    }, [useReward, useWallet, usedReward, usedWallet, jobPrice])

    const handleRewardClose = () => {
        setOpenReward(false)
    }
    const handleRewardAmmount = (value) => {
        if (value) {
            setUsedReward(Number(value))
            setUseReward(true)
        }
        else {
            setUsedReward(0)
            setUseReward(false)
        }
    }

    const handleWalletClose = () => {
        setOpenWallet(false)
    }
    const handleWalletAmmount = (value) => {
        if (value) {
            setUsedWallet(Number(value))
            setUseWallet(true)
        }
        else {
            setUsedWallet(0)
            setUseWallet(false)
        }
    }
    return (
        <>
            <ResponsiveStepper goNext={payForJob} />
            <div className="card2 first-screen show ">

                <div className="row">
                    <div className="col-lg-12">
                        <h4>Pay and Confirm your order</h4>
                    </div>
                    <div className="col-lg-8">
                        <div className="card2-col">

                            <div className="light-gray coupon-code1">
                                <div className="code-flex">
                                    <h6>Coupon Code:</h6>
                                    {promoCode ? <div className="coupon-code">
                                        {promoCode?.promoCode}
                                    </div> : null}
                                </div>
                                <div className="code-flex">
                                    <div className={`promocode_field`}>
                                        <input type='text' id='promocodeInput' />
                                        <button onClick={handlePromoCode}>Apply</button>
                                    </div>
                                </div>
                            </div>

                            <div className="light-gray Redeem-flex">
                                <div className="Redeem-flex-h">
                                    <h4>reward points</h4>
                                    <h5 className='mb-2'>{user?.rewardPoint > 0 ? user?.rewardPoint - usedReward : 0} reward points</h5>
                                    <h5>{user?.rewardPoint > 0 ?(user?.rewardPoint - usedReward)/20 : 0} $</h5>
                                </div>
                                <p className="btn" style={{ backgroundColor: useReward ? 'red' : '#9eb212' }} onClick={() => { !useReward ? setOpenReward(true) : setUseReward(false); setUsedReward(0); dispatch(setRewardPoint({ rewardPointUsed: 0 })) }}>{useReward ? 'Cancel' : 'Redeem'}</p>

                            </div>
                            <div className="light-gray Redeem-flex">
                                <div className="Redeem-flex-h">
                                    <h4>wallet fund</h4>
                                    <h5>{user?.affiliatedAmount - usedWallet} wallet fund</h5>
                                </div>
                                <p className="btn" style={{ backgroundColor: useWallet ? 'red' : '#9eb212' }} onClick={() => { !useWallet ? setOpenWallet(true) : setUseWallet(false); setUsedWallet(0); dispatch(setWalletFund({ walletFundUsed: 0 })) }}>{useWallet ? 'Cancel' : 'Redeem'}</p>

                            </div>

                            <div className="light-gray paymet-in-profile">
                                <div className="f-info">
                                    <div className="row">
                                        <h5>Amount to pay</h5>
                                        <div className={`currency-toggle`}>
                                            <label>
                                                USD
                                                <input type='radio' id="USD" name="currency" value={"USD"} checked={cartCurrency === 'USD'} onChange={(e) => { dispatch(setCartCurrency({ cartCurrency: e.target.value })) }} />
                                            </label>
                                            <label>
                                                JOD
                                                <input type='radio' id="JOD" name='currency' value={"JOD"} checked={cartCurrency === 'JOD'} onChange={(e) => { dispatch(setCartCurrency({ cartCurrency: e.target.value })) }} />
                                            </label>
                                        </div>
                                        <div className="tab-content">
                                            <div id="home" className={`tab-pane ${cartCurrency === "USD" && "active"}`}>
                                                <div className="row">

                                                    <div className="col-lg-12 mb-4 col-md-12">
                                                        <div className="tab-left-content">
                                                            <div className="tab-box-wrap">
                                                                ${price.toFixed(2)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* tab1 */}
                                            <div id="home" className={`tab-pane ${cartCurrency === "JOD" && "active"}`}>
                                                <div className="row">

                                                    <div className="col-lg-12 mb-4 col-md-12">
                                                        <div className="tab-left-content">
                                                            <div className="tab-box-wrap">
                                                                {(price * 0.7).toFixed(2)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* tab2 */}
                                        </div>
                                        <div className="pay-button">
                                            <button type="submit" onClick={payForJob} className="btn w-100">Pay</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="gray-l">
                            <div className="order-dets">
                                <div className="o-flex">
                                    <h5>Order no:</h5>
                                    <p>5454511</p>
                                </div>
                                <div className="o-flex">
                                    <h5>Services:</h5>
                                    <p>{serviceType?.toUpperCase()}</p>
                                </div>
                                {promoCode ? <div className="o-flex">
                                    <h5>Coupon Code:</h5>
                                    <p>{promoCode?.promoCode}</p>
                                </div> : null}
                                <div className="o-flex">
                                    <h5>Costs:</h5>
                                    <p>${jobPrice.toFixed(2)}</p>
                                 </div>
                                <div className="o-flex">
                                    <h5>Discount:</h5>
                                    <p>${discount}</p><p>%{promoCode.discountAmount}</p>
                                </div>
                                <div className="o-flex">
                                    <h5>Reward points:</h5>
                                    <p>{rewardPointUsed} points</p>
                                </div>
                                <div className="o-flex">
                                    <h5>Wallet fund:</h5>
                                    <p>{walletFundUsed.toFixed(2)}</p>
                                </div>

                                <div className="o-flex total-or">
                                    <h5>Total:</h5>
                                    <h5>{price.toFixed(2)}</h5>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <PromoCodeModal showModal={showModal} setShowModal={setShowModal}
             jobPrice={savePrice} handleJobPrice={handleJobPrice} />
            <ReedemRewardpointModal open={openReward} handleClose={handleRewardClose}
             handleAmmount={handleRewardAmmount} jobPrice={price} 
             checkPrice={(user?.rewardPoint * (5/100)).toFixed(0)} />
            <ReedemWalletModal open={openWallet} handleClose={handleWalletClose} handleAmmount={handleWalletAmmount} jobPrice={price} checkPrice={user?.affiliatedAmount} />
        </>
    )
}

export default Payment