import React, { useEffect, useState } from 'react'
import RecentProjects from '../../components/common/recentProjectSlider/RecentProjectsSlider'
import { useDispatch, useSelector } from 'react-redux'
import { getDashboardData, getProfile } from '../../services/api/profileApi'
import { setJobCounts } from '../../redux/reducers/dashBoardSlice'
import { toast } from 'react-toastify'
import SocialShare from '../../components/common/socialShare/SocialShare'
import { setSelectedJobDetails, setSelectedReceiptDetails } from '../../redux/reducers/jobSlice'
import { useNavigate } from 'react-router-dom'
import { updateUser } from '../../redux/reducers/authSlice'
import RecentTranscationSlider from '../../components/common/TransactionSlider/RecentTranscationSlider'
import WithDrawModelCustomer from '../../components/common/WithdrawModel/WithDrawModelCustomer'
import { checkWordCount, uploadJobFile } from '../../services/api/jobPostApis'
import { setActiveStep, updateBlobFile, updateJobPostFileWordCount, setCheckMultipleFiles, setUploadedSubFiles, clearjobPost } from '../../redux/reducers/jobPostSlice'
import DragDropUploadButton from '../../components/common/DragDropUploadButton'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import QRCode from "react-qr-code";
import ProgressBar from 'react-bootstrap/ProgressBar';

const CustomerDashboard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showWithDrawModel, setShowWithDrawModel] = useState(false)
  const { user } = useSelector((state) => state.auth)
  const { cancelJobs, completedProjects, ongoingProjects } = useSelector((state) => state.dashBoard)
  const [showSocialShare, setShowSocialShare] = useState(false)
  const { jobWordCount, uploadFiles, blobFile, fileDetails, checkMultipleFiles, uploadedSubFiles } = useSelector((state) => state.jobPost)
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(50);
  const [showProgressBarFiles, setShowProgressBarFiles] = useState(false);
  const [progressPercentageFiles, setProgressPercentageFiles] = useState(10);

  const copyFunc = async () => {
    await navigator.clipboard.writeText(`${window?.location?.origin}/signup?code=${user?._id}`);
    toast.success('Affiliate link copied successfully')
  }
  const downloadQR = () => {
    const svg = document.getElementById("qrcode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
  
      const downloadLink = document.createElement("a");
      downloadLink.download = "qrcode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
  
    img.src = "data:image/svg+xml;base64," + btoa(svgData);
  };

  useEffect(() => {
    (async () => {
      let dashbordData = await getDashboardData()
      let payload = {
        cancelJobs: dashbordData?.result?.data?.cancelJobs,
        completedProjects: dashbordData?.result?.data?.completedProjects,
        ongoingProjects: dashbordData?.result?.data?.ongoingProjects
      }
      dispatch(setJobCounts(payload))
    })()
  }, [])
  const gotoSelectedProject = (data) => {
    dispatch(setSelectedJobDetails({ selectedJob: data }))
    navigate(`/job-details`, { state: { id: data._id } })
  }
  const gotoSelectedReceipt = (data) => {
    dispatch(setSelectedReceiptDetails({ selectedReceipt: data }))
    navigate(`/customer/receipt-details`, { state: { id: data._id } })
  }
  const getUserDetails = async () => {
    let resultData = await getProfile()
    dispatch(updateUser({ user: resultData?.result?.data }));
  }
  const cancelThedata = () => {
    dispatch(clearjobPost())
    setShowProgressBar(false)
    setShowProgressBarFiles(false)
    dispatch(updateBlobFile({ blobFile: null }))
    dispatch(setCheckMultipleFiles({checkMultipleFiles: false}));
    dispatch(setUploadedSubFiles({ uploadedSubFiles: [] }));
    setProgressPercentage(0)
    setProgressPercentageFiles(0);
    navigate('/customer-dashboard')
  }
  useEffect(() => {
    getUserDetails()
  }, [])
  const wordCountCheck = async (data) => {
    const maxSizeInBytes = 10 * 1024 * 1024; 
    if (data[0]?.size > maxSizeInBytes) {
        console.log("File size must be less than 10 MB");
        toast.error("File size must be less than 10 MB")
        return
    }
    setShowProgressBar(true);
    let uploadFileData = await checkWordCount(data[0])
    if (uploadFileData?.result) {


      dispatch(updateJobPostFileWordCount({ count: uploadFileData?.result?.data?.wordCount }))
      blobToBase64(data[0])
        .then(base64String => {
          dispatch(updateBlobFile({ blobFile: { b64: base64String, name: data[0]?.path } }))
          setShowProgressBar(false);
          dispatch(setActiveStep({ activeStep: 'uploadFile' }))
          navigate('/job-post/upload-file')
          
        })
        .catch(error => {
          console.error('Error converting blob to base64:', error);
        });


    }
  }
  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
  const withDrawModelOpen = () => {
    if (user.country !== "") {
      setShowWithDrawModel(true)
    }
    else {
      toast.error("Please Select Country From the Edit Profile")
    }
  }
  const toggleMult = (val)=>{
    dispatch(setCheckMultipleFiles({checkMultipleFiles: val}))
  }
  function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[arr.length - 1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
  }
  const handleMultipleFiles = async (acceptedFiles) => {
      let totalWordCount = 0;
      let newFiles = [];
      let counter = 1;
      let blobFileData = {}
      for (const file of acceptedFiles) {
          setShowProgressBarFiles(true)
          const wordCountData = await checkWordCount(file);
          if (wordCountData?.result) {
              totalWordCount += Number(wordCountData.result.data.wordCount);
              try {
                  const base64String = await blobToBase64(file);
                  const uploadFileData = await uploadJobFile(dataURLtoFile(base64String, file.path));
                  
                  if (uploadFileData?.result) {
                      const fileData = uploadFileData.result.data.responseData;
                      fileData.wordCount = wordCountData.result.data.wordCount;
                      fileData.id = counter;
                      fileData.fromLang = ''
                      fileData.toLang = ''
                      fileData.additionalComment = ''
                      fileData['certificate'] = false;
                      newFiles.push(fileData);
                      counter ++;
                  }
                  if(counter === acceptedFiles.length){
                      blobFileData = { b64: base64String, name: file.path }
                  }
              } catch (error) {
                  console.error("Error converting blob to base64 or uploading file:", error);
              }
          
          }
      }

      dispatch(updateBlobFile({
          blobFile: blobFileData
      }));
      setProgressPercentageFiles(99);
      setShowProgressBarFiles(false)
      dispatch(setCheckMultipleFiles({ checkMultipleFiles: true }));
      dispatch(setUploadedSubFiles({ uploadedSubFiles: [...uploadedSubFiles, ...newFiles] }));
      dispatch(updateJobPostFileWordCount({ count: totalWordCount }));
      navigate('/job-post/upload-file')
  };
  useEffect(() => {
    let interval;
    if (showProgressBar) {
        interval = setInterval(() => {
            setProgressPercentage((prev) => {
                if (prev < 90) {
                    return prev + 10; 
                }
                clearInterval(interval);
                return 99;
            });
        }, 1000);
    }

    return () => {
        clearInterval(interval); 
    };
  }, [showProgressBar]);

  useEffect(() => {
    let interval;
    if (showProgressBarFiles) {
        interval = setInterval(() => {
            setProgressPercentageFiles((prev) => {
                if (prev < 90) {
                    return prev + 10; 
                }
                clearInterval(interval);
                return 99;
            });
        }, 1000);
    }

    return () => {
        clearInterval(interval); 
    };
  }, [showProgressBarFiles]);
  return (
    <>
      <div className="user-dashbord">
        <div className="section-header-dashbord common-btm-mrgn">
          <div className="container">
            <div className="content">
              <h2>Welcome! {user.name}.</h2>
            </div>
          </div>
        </div>

        <div className="dashbord-three-box common-btm-mrgn">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="sngl-box blue-box">
                  <h3><span><img src="/images/tickdb.svg" alt="" /></span> {ongoingProjects}</h3>
                  <h5>Ongoing Projects</h5>
                  <p>{ongoingProjects}</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="sngl-box green-box">
                  <h3><span><img src="/images/tickdb.svg" alt="" /></span> {completedProjects}</h3>
                  <h5>Completed Projects</h5>
                  <p>{completedProjects}</p>
                </div>
              </div>
              <div className="col-lg-4" style={{ marginTop: "-43px" }}>
                <section className="steps-sec">
                      <div className="container">
                          <ul style={{maxWidth:"250px"}} className="nav nav-tabs my-2 responsive-tab" role="tablist">
                              <li className="nav-item text-center" onClick={() => toggleMult(false)}>
                                  <a   className={`files-upload one-file-upload  ${!checkMultipleFiles && "active"} `} data-bs-toggle="tab" href="#home">
                                  One File
                                  </a>
                              </li>
                              <li className="nav-item" onClick={() => toggleMult(true)}>
                                  <a className={`files-upload  ${checkMultipleFiles && "active"} `} data-bs-toggle="tab" href="#menu1">
                                  Multiple Files
                                  </a>
                              </li>
                          </ul>
                      </div>
                </section>

                {!checkMultipleFiles ?( !showProgressBar ? <div className="sngl-box green-box" >
                  <div className="zone">
                    <div id="dropZ">
                      <img src="/images/file.png" alt="" />
                      <div className="drag-text">Drag & drop your entire document here.</div>
                      <div className="drag-text">Supported file types: 
                        Images; Documents (.docx); Spreadsheets (.xlxs , .csv); PDF; Powerpoint (.pptx)</div>
                      <div className="pro-or">
                        <span> OR</span>
                      </div>

                      <DragDropUploadButton btnCss={"upload-f"} btnName={"Browse File"} multiple={false} disabled={!!blobFile} />
                      <DragDropUploadButton btnName={""} multiple={false} onChange={wordCountCheck} disabled={!!blobFile} />

                    </div>

                  </div>
                </div>: <ProgressBar className='my-5' animated striped  now={progressPercentage} label={`${progressPercentage}%`}/>) : (!showProgressBarFiles?<div className="sngl-box green-box">
                  <div className="zone">
                    <div id="dropZ">
                      <img src="/images/file.png" alt="" />
                      <div className="drag-text">Drag & drop your entire documents here.</div>
                      <div className="drag-text">Supported file types: 
                        Images; Documents (.docx); Spreadsheets (.xlxs , .csv); PDF; Powerpoint (.pptx)</div>
                      <div className="pro-or">
                        <span> OR</span>
                      </div>

                      <DragDropUploadButton btnCss={"upload-f"} btnName={"Browse Files"} multiple={true} onChange={(files) => handleMultipleFiles(files)}  disabled={!!blobFile}/>
                      <DragDropUploadButton btnName={""} multiple={true} onChange={handleMultipleFiles} disabled={!!blobFile} />

                    </div>

                  </div>
                </div>:<ProgressBar className='my-5' animated striped  now={progressPercentageFiles} label={`${progressPercentageFiles}%`}/>
                    )}
              </div>
            </div>
          </div>
        </div>

        <div className="dashbord-aflit-link common-btm-mrgn-big">
          <div className="container">
            <div className="content light-green-bg">
              {<div className="ext-content">
                <h5>Reward Points</h5>
                <div className="row">
                  <div className="col-lg-7">
                    <div className="row">
                      <div className="col-lg-5">
                        <div className="sngl-box">
                          <h5>Points</h5>
                          <h2>{user?.rewardPoint}(${(user?.rewardPoint * (5/100)).toFixed(0)})</h2>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        {/* <div className="sngl-box">
                          <h5>Total withdraw</h5>
                          <h4>$200</h4>
                        </div> */}
                      </div>
                      {/* <div className="col-lg-4">
                        <div className="sngl-box">
                          <h5>Total Points</h5>
                          <h4>{user?.rewardPoint}</h4>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="col-lg-5">
                    <div className="right-content">
                      <ul className="btn-box">
                        <li><a href="" className="btn">Change PayPal Email</a></li>
                        <li><a href="" className="btn">Withdraw</a></li>
                      </ul>
                      <p>Get $50 to withdraw</p>
                    </div>
                  </div> */}
                </div>
              </div>}
              <div className="ext-content">
                <h4>Affiliate Link</h4>
                <form>
                  <div className="frm-box-wrap">


                    <div className="frm-box">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="custom-search">
                            <input type="text" className="form-control custom-search-input" id="" aria-describedby="" placeholder="" value={`${window?.location?.origin}/signup?code=${user?._id}`} />

                            <button type="button" className="copy-link custom-search-botton" onClick={copyFunc}>
                              <FontAwesomeIcon icon={faCopy} />
                            </button>
                          </div>
                          <button type="button" className="btn" onClick={() => setShowSocialShare(true)} ><span><img src="/images/sear.svg" /></span> Share</button>
                        </div>
                        <div className="col-lg-4">
                          <div style={{ margin: "0", "borderLeft": "1px solid #c9c9c9", "paddingLeft": "30px" }}>
                            <QRCode
                              size={128}
                              value={`${window?.location?.origin}/signup?code=${user?._id}`}
                              viewBox={`0 0 128 128`}
                              id="qrcode"
                            />
                            <button onClick={() => downloadQR()} className="btn" style={{ "marginLeft": "30px", "verticalAlign": "top" }}>
                              <FontAwesomeIcon icon={faDownload} /> Download
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </form>
              </div>

              {<div className="ext-content">
                <h5>Affiliate Earnings</h5>
                <div className="row">
                  <div className="col-lg-7">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="sngl-box">
                          <h5>Total earnings</h5>
                          <h2>${user?.affiliatedAmount}</h2>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        {/* <div className="sngl-box">
                          <h5>Total withdraw</h5>
                          <h4>$200</h4>
                        </div> */}
                      </div>
                      <div className="col-lg-4">
                        <div className="sngl-box">
                          <h5>Total balance</h5>
                          <h4>${user?.affiliatedAmount}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="right-content">
                      <ul className="btn-box">
                        {/* <li><a href="" className="btn">Change PayPal Email</a></li> */}
                        <li ><p
                          style={{
                            cursor: user?.affiliatedAmount > 50
                              ? ""
                              : "not-allowed",
                            backgroundColor: user?.affiliatedAmount < 50 && "gray"
                          }}
                          onClick={() => user?.affiliatedAmount > 50 && withDrawModelOpen()} className="btn">Withdraw</p></li>
                      </ul>
                      <p>Balance should be minimum of $50 to withdraw</p>
                    </div>
                  </div>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
      <RecentProjects gotoSelectedProject={gotoSelectedProject} />
      <RecentTranscationSlider gotoSelectedReceipt={gotoSelectedReceipt} />

      <SocialShare setShowSocialShare={setShowSocialShare} showSocialShare={showSocialShare} />
      {showWithDrawModel && <WithDrawModelCustomer showWithDrawModel={showWithDrawModel} setShowWithDrawModel={setShowWithDrawModel} />}
    </>

  )
}

export default CustomerDashboard