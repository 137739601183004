import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const ReedemRewardpointModal = ({ open, handleClose, handleAmmount, jobPrice, checkPrice }) => {
    let maxPoints = (jobPrice - 1) * 20; // Calculate max reward points that can be used
    console.log(checkPrice);
    
    if(checkPrice <= 0){
        maxPoints = 0
    }
    const [price, setPrice] = useState(maxPoints); // Set initial points to max usable points

    const changePrice = (e) => {
        let newPrice = Number(e.target.value);

        // Restrict changes to multiples of 20 and ensure the new price keeps jobPrice above $1
        if (!isNaN(newPrice) && newPrice >= 0 && newPrice % 20 === 0) {
            const maxRedeemablePrice = Math.min(newPrice, maxPoints);
            setPrice(maxRedeemablePrice);
        }
    };

    useEffect(() => {
        if (open) {
            setPrice(maxPoints);
        } else {
            setPrice(0); 
        }
    }, [open, maxPoints]);

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={open}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Use Reward Points
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-lg-12">
                    <div className="form-group">
                        {maxPoints === price ? <label className='text-warning'>The maximum points to Redeem</label> : <label>Points to Redeem</label>}
                        <input
                            type="number"
                            placeholder=""
                            className="form-control"
                            value={price}
                            onChange={changePrice}
                            step="20"
                            min="0"
                            max={maxPoints}
                        />
                        <small className="form-text text-muted">
                            Points can only be redeemed in increments of 20.
                        </small>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => { handleAmmount(price); handleClose(); }}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ReedemRewardpointModal;
