import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const ReedemWalletModal = ({ open, handleClose, handleAmmount, jobPrice, checkPrice }) => {
    const [price, setPrice] = useState(0);
    const [maxReached, setMaxReached] = useState(false);

    const changePrice = (e) => {
        const value = Number(e?.target?.value);
        if (!isNaN(value)) {
            // Ensure price doesn't drop below $1
            const maxRedeemable = jobPrice - 1;
            const validPrice = Math.max(0, Math.min(value, checkPrice, maxRedeemable));
            setPrice(validPrice);
    
            // Check if max redeemable amount is reached
            setMaxReached(validPrice === maxRedeemable);
        }
        // const value = Number(e?.target?.value);
        // if (!isNaN(value)) {
        //     // Ensure price doesn't drop below $1
        //     const maxRedeemable = jobPrice - 1;
        //     const validPrice = value > 0 ? Math.min(value, checkPrice, maxRedeemable) : 0;
        //     setPrice(validPrice);

        //     // Check if max redeemable amount is reached
        //     setMaxReached(validPrice === maxRedeemable);
        // }
    };

    useEffect(() => {
        if (!open) {
            setPrice(0);
            setMaxReached(false);
        }
    }, [open]);

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={open}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Use wallet amount
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-lg-12">
                    <div className="form-group">
                        <label>
                        {maxReached ? <label className='text-warning'>The maximum amount to redeem</label> : <label>Amount to redeem</label>}
                        </label>
                        <input
                            type="number"
                            placeholder=""
                            className="form-control"
                            value={price.toFixed(2)}
                            onChange={changePrice}
                            // disabled={maxReached} // Disable input when max is reached
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        handleAmmount(price);
                        handleClose();
                    }}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ReedemWalletModal;
