import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { removeFileFromS3, checkWordCount, uploadJobFile } from '../../../services/api/jobPostApis'
import { useDispatch, useSelector } from 'react-redux'
import { clearjobPost, setActiveStep, updateBlobFile, updateJob, updateJobPostFileWordCount, setCheckMultipleFiles, setUploadedSubFiles, updateJobPostFiles } from '../../../redux/reducers/jobPostSlice'
import DragDropUploadButton from '../../../components/common/DragDropUploadButton'
import { useFormik } from 'formik'
import { uploadJobFileFormValidator } from '../../../services/formValidator/jobValidator'
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import ResponsiveStepper from './ResponsiveStepper'
import { getProfile } from '../../../services/api/profileApi'
import { updateUser } from '../../../redux/reducers/authSlice'
import ProgressBar from 'react-bootstrap/ProgressBar';
const UploadFile = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [progressPercentage, setProgressPercentage] = useState(50);
    const [progressPercentageFiles, setProgressPercentageFiles] = useState(10);
    const [showProgressBarFiles, setShowProgressBarFiles] = useState(false);

    const { jobWordCount, subFiles, blobFile, fileDetails, checkMultipleFiles, uploadedSubFiles } = useSelector((state) => state.jobPost)
    useEffect(() => {
        (async () => {
            if (blobFile && !subFiles) {
                wordCountCheck(blobFile)
            }
        })()
    }, [])
    function  blobToBase64(blob)  {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }
    const wordCountCheck = async (data) => {
        const maxSizeInBytes = 10 * 1024 * 1024; 
        if (data[0]?.size > maxSizeInBytes) {
            console.log("File size must be less than 10 MB");
            toast.error("File size must be less than 10 MB");
            return
        }
        setShowProgressBar(true);
        setShowProgressBarFiles(true);
        let uploadFileData = await checkWordCount(data[0])
        if (uploadFileData?.result) {
            dispatch(updateJobPostFileWordCount({ count: uploadFileData?.result?.data?.wordCount }))
            // tryca
            blobToBase64(data[0])
                .then(base64String => {
                    dispatch(updateBlobFile({ blobFile: { b64: base64String, name: data[0]?.path } }))
                })
                .catch(error => {
                    console.error('Error converting blob to base64:', error);
                });
                setShowProgressBar(false);
        }
    }
    const removeItem = async () => {
        setShowProgressBar(false)
        setShowProgressBarFiles(false)
        dispatch(updateBlobFile({ blobFile: null }))
        dispatch(setCheckMultipleFiles({checkMultipleFiles: false}));
        dispatch(setUploadedSubFiles({ uploadedSubFiles: [] }));
        setProgressPercentageFiles(0);
        setProgressPercentage(0)

    }
    // this use effect handle active route
    useEffect(() => {
        dispatch(setActiveStep({ activeStep: 'uploadFile' }))
    }, [])
    const gotoNextPage = () => {
        if (jobWordCount) {
            uploadFileNameDescFormik.handleSubmit()
            if (jobWordCount > 50000) {
                toast.success("Good news! Since this is a large-scale project, we decided to give you a 10% discount.")
            }
        }
        else {
            toast.error("Please upload the file")
        }
    }

    const uploadFileNameDescFormik = useFormik({
        initialValues: uploadJobFileFormValidator(fileDetails, 'initialValues'),
        validationSchema: checkMultipleFiles ? uploadJobFileFormValidator(fileDetails, 'validationSchemaJob'): uploadJobFileFormValidator(fileDetails, 'validationSchema'),
        onSubmit: (values) => {
            dispatch(updateJob({
                type: 'UPDATE_FILE_DETAILS',
                value: values
            }))
            dispatch(setActiveStep({ activeStep: 'typeOfDoc' }))
            navigate('/job-post/document-type')
        }
    })
    const getUserDetails = async () => {
        let resultData = await getProfile()
        dispatch(updateUser({ user: resultData?.result?.data }));
    }

    useEffect(() => {
        getUserDetails()
    }, [])
    const cancelThedata = () => {
        dispatch(clearjobPost())
        setShowProgressBar(false)
        setShowProgressBarFiles(false)
        dispatch(updateBlobFile({ blobFile: null }))
        dispatch(setCheckMultipleFiles({checkMultipleFiles: false}));
        dispatch(setUploadedSubFiles({ uploadedSubFiles: [] }));
        setProgressPercentage(0)
        setProgressPercentageFiles(0);
        navigate('/customer-dashboard')
    }
    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }
    const handleDocNameChange = (e, id) => {
        const newDocName = e.target.value;
        const updatedFiles = uploadedSubFiles.map(file => 
            file.id === id ? { ...file, docName: newDocName } : file
        );
        
        dispatch(setUploadedSubFiles({ uploadedSubFiles: updatedFiles }));
    };
    const handleMultipleFiles = async (acceptedFiles) => {
        let totalWordCount = 0;
        let newFiles = [];
        let counter = 1;
        let blobFileData = {}
        for (const file of acceptedFiles) {
            setShowProgressBarFiles(true)
            const wordCountData = await checkWordCount(file);
            if (wordCountData?.result) {
                totalWordCount += Number(wordCountData.result.data.wordCount);
                try {
                    const base64String = await blobToBase64(file);
                    const uploadFileData = await uploadJobFile(dataURLtoFile(base64String, file.path));
                    
                    if (uploadFileData?.result) {
                        const fileData = uploadFileData.result.data.responseData;
                        fileData.wordCount = wordCountData.result.data.wordCount;
                        fileData.id = counter;
                        fileData.fromLang = ''
                        fileData.toLang = ''
                        fileData.additionalComment = ''
                        fileData['certificate'] = false;
                        newFiles.push(fileData);
                        counter ++;
                    }
                    if(counter === acceptedFiles.length){
                        blobFileData = { b64: base64String, name: file.path }
                    }
                } catch (error) {
                    console.error("Error converting blob to base64 or uploading file:", error);
                }
            
            }
        }

        dispatch(updateBlobFile({
            blobFile: blobFileData
        }));
        setShowProgressBarFiles(false)
        dispatch(setCheckMultipleFiles({ checkMultipleFiles: true }));
        dispatch(setUploadedSubFiles({ uploadedSubFiles: [...uploadedSubFiles, ...newFiles] }));
        dispatch(updateJobPostFileWordCount({ count: totalWordCount }));
    };
    
    const toggleMult = (val)=>{
        dispatch(setCheckMultipleFiles({checkMultipleFiles: val}))
    }
    useEffect(() => {
        let interval;
        if (showProgressBar) {
            interval = setInterval(() => {
                setProgressPercentage((prev) => {
                    if (prev < 90) {
                        return prev + 10; 
                    }
                    clearInterval(interval);
                    return 99;
                });
            }, 1000);
        }
    
        return () => {
            clearInterval(interval); 
        };
    }, [showProgressBar]);
    
    useEffect(() => {
        let interval;
        if (showProgressBarFiles) {
            interval = setInterval(() => {
                setProgressPercentageFiles((prev) => {
                    if (prev < 90) {
                        return prev + 10; 
                    }
                    clearInterval(interval);
                    return 99;
                });
            }, 1000);
        }
    
        return () => {
            clearInterval(interval); 
        };
    }, [showProgressBarFiles]);
    return (
        <>
            <ResponsiveStepper goNext={gotoNextPage} />
            <div className="card2 show">
                <section className="steps-sec">
                    <div className="container">
                        <ul style={{maxWidth:"250px"}} className="nav nav-tabs my-2 responsive-tab" role="tablist">
                            <li className="nav-item text-center" onClick={() => toggleMult(false)}>
                                <a   className={`files-upload one-file-upload  ${!checkMultipleFiles && "active"} `} data-bs-toggle="tab" href="#home">
                                One File
                                </a>
                            </li>
                            <li className="nav-item" onClick={() => toggleMult(true)}>
                                <a className={`files-upload  ${checkMultipleFiles && "active"} `} data-bs-toggle="tab" href="#menu1">
                                Multiple Files
                                </a>
                            </li>
                        </ul>
                    </div>
                </section>
                {blobFile ?
                    <div className="file-list-table">
                        <div className="signin-form-wrap e-ch upload-file-section">
                            <div className="form-group">
                               {checkMultipleFiles ?  <label>Job Name</label>:  <label>Document Name</label>}
                                <input type="text" placeholder={checkMultipleFiles?'Enter a name for this job to help you recognize it later.':'Enter a name for this document to help you recognize it later.'} id='name' name='name' onChange={uploadFileNameDescFormik.handleChange} value={uploadFileNameDescFormik?.values?.name} className="form-control" />
                                <p className='form-error-message'>{uploadFileNameDescFormik.touched.name &&
                                    uploadFileNameDescFormik.errors.name}</p>
                            </div>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        {checkMultipleFiles?
                                        <tr>
                                        <th className='mw-2'>No.</th>
                                        <th >{checkMultipleFiles ? "File names":"File name"}</th>
                                        <th >Doc Name</th>
                                        <th className='pt-8'>Word Count</th>
                                        <th></th>
                                        </tr>
                                        :<tr>
                                            <th colSpan="2">{checkMultipleFiles ? "File names":"File name"}</th>
                                            <th scope="col">Word Count</th>
                                            <th scope="col"></th>

                                        </tr>
                                        }
                                    </thead>
                                    <tbody>
                                        {checkMultipleFiles?
                                        <>
                                       {
                                       uploadedSubFiles.map((file, index) => (
                                        <tr key={index}>
                                            {/* Order Number */}
                                            <td>{file.id}</td>
                                    
                                            {/* File Name with Fixed Width and Truncation */}
                                            <td colSpan="1">
                                                <div className="d-flex align-items-center">
                                                    <i className="fas fa-file-alt mr-2"></i>
                                                    <span className="text-truncate" style={{ maxWidth: '150px' }} title={file?.name}>{file?.name}</span>
                                                </div>
                                            </td>
                                    
                                            {/* Document Name Input */}
                                            <td>
                                            <input 
                                                type="text" 
                                                placeholder="Enter document name" 
                                                className=" border-0 shadow-none font-weight-bold p-0" 
                                                onChange={(e) => handleDocNameChange(e, file.id)} 
                                                value={file.docName || ''}
                                            />
                                            </td>
                                    
                                            {/* Word Count */}
                                            <td>{file.wordCount}</td>
                                    
                                            {/* Delete Button */}
                                            <td>
                                            <p  onClick={() => removeItem(index)}><FontAwesomeIcon icon={faTrashAlt} /></p>
                                            </td>
                                        </tr>
                                       ))}
                                    
                                        </>
                                        :
                                        
                                        <tr>
                                            <td colSpan="2">
                                                <h6><span><i className="fas fa-file-alt"></i></span>{blobFile?.name}</h6>
                                            </td>
                                            <td>{jobWordCount}</td>
                                            <td><p onClick={removeItem}><FontAwesomeIcon icon={faTrashAlt} /></p></td>
                                        </tr>
                                        
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                    { !checkMultipleFiles ?
                        (!showProgressBar?<div className="profile-form-box" style={{ position: 'relative' }}>
                        <div className="zone">
                            <div id="dropZ">
                                <img src="/images/file.png" alt="" />
                                <div className="drag-text">Drag & drop your entire document here.</div>
                                <div className="drag-text">Supported file types:
                                    Images; Documents (.docx); Spreadsheets (.xlxs , .csv); PDF; Powerpoint (.pptx)</div>
                                <div className="pro-or mt-4">
                                    <span> OR</span>
                                </div>
                                <DragDropUploadButton btnCss={"upload-f"} btnName={"Browse File"} multiple={false} disabled={!!blobFile} />
                                <DragDropUploadButton btnName={""} multiple={false} onChange={wordCountCheck} disabled={!!blobFile} />
                            </div>
                        </div>
                    </div>: <ProgressBar className='my-5' animated striped  now={progressPercentage} label={`${progressPercentage}%`}/> )
                    
                    : (!showProgressBarFiles?
                    <div className="profile-form-box" style={{ position: 'relative' }}>
                        <div className="zone">
                            <div id="dropZ">
                                <img src="/images/file.png" alt="" />
                                <div className="drag-text">Drag & drop your entire documents here.</div>
                                <div className="drag-text">Supported file types:
                                    Images; Documents (.docx); Spreadsheets (.xlxs , .csv); PDF; Powerpoint (.pptx)</div>
                                <div className="pro-or mt-4">
                                    <span> OR</span>
                                </div>
                                <DragDropUploadButton btnCss={"upload-f"} btnName={"Browse Files"} multiple={true} onChange={(files) => handleMultipleFiles(files)}  disabled={!!blobFile}/>
                                <DragDropUploadButton btnName={""} multiple={true} onChange={handleMultipleFiles} disabled={!!blobFile} />
                            </div>
                        </div>
                    </div>:<ProgressBar className='my-5' animated striped  now={progressPercentageFiles} label={`${progressPercentageFiles}%`}/>)
                    }
                    </>
                }
                <div className="button-set">
                    <div onClick={cancelThedata} className="Cancel-button text-center mt-1 ml-2 btn btn-border-black">Cancel
                    </div>
                    <div onClick={gotoNextPage} className="next-button text-center mt-1 ml-2 btn">Next
                    </div>
                </div>
            </div>
        </>
    )
}

export default UploadFile