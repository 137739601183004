import React, { Fragment, useEffect, useState } from "react";
import RecentProjects from "../../components/common/recentProjectSlider/RecentProjectsSlider";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData, getProfile, downloadAllPaidReceiptsReq, downloadAllUnPaidIvoicesReq } from "../../services/api/profileApi";
import { setJobCounts } from "../../redux/reducers/dashBoardSlice";
import { toast } from "react-toastify";
import SocialShare from "../../components/common/socialShare/SocialShare";
import {
  setSelectedJobDetails,
  setSelectedReceiptDetails,
} from "../../redux/reducers/jobSlice";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../../redux/reducers/authSlice";
import RecentTranscationSlider from "../../components/common/TransactionSlider/RecentTranscationSlider";
import WithDrawModelCustomer from "../../components/common/WithdrawModel/WithDrawModelCustomer";
import { checkWordCount, uploadJobFile } from "../../services/api/jobPostApis";
import {
  setActiveStep,
  updateBlobFile,
  updateJobPostFileWordCount, setCheckMultipleFiles, setUploadedSubFiles
} from "../../redux/reducers/jobPostSlice";
import DragDropUploadButton from "../../components/common/DragDropUploadButton";
import ProgressBar from 'react-bootstrap/ProgressBar';
import TableBusiness from "./TableBusiness";


const buttonStyle = {
  padding: "12px 20px",
  background: "#141d6d",
  color: "white",
  fontWeight: "bold",
  borderRadius: "5px",
};

const BusinessDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showWithDrawModel, setShowWithDrawModel] = useState(false);
  const [userDetail, setUserDetail] = useState(null);
  const { user, company } = useSelector((state) => state.auth);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(50);
  const [progressPercentageFiles, setProgressPercentageFiles] = useState(10);
  const [showProgressBarFiles, setShowProgressBarFiles] = useState(false);
  const { cancelJobs, completedProjects, ongoingProjects } = useSelector(
    (state) => state.dashBoard
  );
  const [showSocialShare, setShowSocialShare] = useState(false);
  const { jobWordCount, uploadFiles, blobFile, fileDetails, checkMultipleFiles, uploadedSubFiles } = useSelector(
    (state) => state.jobPost
  );
  const downloadAllPaidReceipts = async () => {
    downloadAllPaidReceiptsReq();
    toast.success("Request sent to server successfully")
  };
  const downloadAllUnPaidInvoices = async () => {
    downloadAllUnPaidIvoicesReq();
    toast.success("Request sent to server successfully")
  };
  useEffect(() => {
    (async () => {
      let dashbordData = await getDashboardData();
      let payload = {
        cancelJobs: dashbordData?.result?.data?.cancelJobs,
        completedProjects: dashbordData?.result?.data?.completedProjects,
        ongoingProjects: dashbordData?.result?.data?.ongoingProjects,
      };
      dispatch(setJobCounts(payload));
    })();
  }, []);
  const getUserDetails = async () => {
    let resultData = await getProfile();
    dispatch(updateUser({ user: resultData?.result?.data }));
    setUserDetail(resultData?.result?.data);
  };

  useEffect(() => {
    console.log(company)
    getUserDetails();
  }, []);

  const wordCountCheck = async (data) => {
    const maxSizeInBytes = 10 * 1024 * 1024; 
    if (data[0]?.size > maxSizeInBytes) {
        console.log("File size must be less than 10 MB");
        toast.error("File size must be less than 10 MB")
        return
    }
    setShowProgressBar(true);
    setShowProgressBarFiles(true);
    let uploadFileData = await checkWordCount(data[0]);
    if (uploadFileData?.result) {
      dispatch(
        updateJobPostFileWordCount({
          count: uploadFileData?.result?.data?.wordCount,
        })
      );
      blobToBase64(data[0])
        .then((base64String) => {
          dispatch(
            updateBlobFile({
              blobFile: { b64: base64String, name: data[0]?.path },
            })
          );
          setShowProgressBar(false);
          dispatch(setActiveStep({ activeStep: "uploadFile" }));
          navigate("/job-post/upload-file");
        })
        .catch((error) => {
          console.error("Error converting blob to base64:", error);
        });
    }
  };
  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  const handleMultipleFiles = async (acceptedFiles) => {
    let totalWordCount = 0;
    let newFiles = [];
    let counter = 1;
    let blobFileData = {}
    for (const file of acceptedFiles) {
        setShowProgressBarFiles(true)
        const wordCountData = await checkWordCount(file);
        if (wordCountData?.result) {
            totalWordCount += Number(wordCountData.result.data.wordCount);
            try {
                const base64String = await blobToBase64(file);
                const uploadFileData = await uploadJobFile(dataURLtoFile(base64String, file.path));
                
                if (uploadFileData?.result) {
                    const fileData = uploadFileData.result.data.responseData;
                    fileData.wordCount = wordCountData.result.data.wordCount;
                    fileData.id = counter;
                    fileData.fromLang = ''
                    fileData.toLang = ''
                    fileData.additionalComment = ''
                    fileData['certificate'] = false;
                    newFiles.push(fileData);
                    counter ++;
                }
                if(counter === acceptedFiles.length){
                    blobFileData = { b64: base64String, name: file.path }
                }
            } catch (error) {
                console.error("Error converting blob to base64 or uploading file:", error);
            }
        
        }
    }

    dispatch(updateBlobFile({
        blobFile: blobFileData
    }));
    setProgressPercentageFiles(99);
    setShowProgressBarFiles(false)
    dispatch(setCheckMultipleFiles({ checkMultipleFiles: true }));
    dispatch(setUploadedSubFiles({ uploadedSubFiles: [...uploadedSubFiles, ...newFiles] }));
    dispatch(updateJobPostFileWordCount({ count: totalWordCount }));
    navigate('/job-post/upload-file')
  };
  const toggleMult = (val)=>{
    dispatch(setCheckMultipleFiles({checkMultipleFiles: val}))
  }
  useEffect(() => {
    let interval;
    if (showProgressBar) {
        interval = setInterval(() => {
            setProgressPercentage((prev) => {
                if (prev < 90) {
                    return prev + 10; 
                }
                clearInterval(interval);
                return 99;
            });
        }, 1000);
    }

    return () => {
        clearInterval(interval); 
    };
  }, [showProgressBar]);
  
  useEffect(() => {
    let interval;
    if (showProgressBarFiles) {
        interval = setInterval(() => {
            setProgressPercentageFiles((prev) => {
                if (prev < 90) {
                    return prev + 10; 
                }
                clearInterval(interval);
                return 99;
            });
        }, 1000);
    }

    return () => {
        clearInterval(interval); 
    };
  }, [showProgressBarFiles]);

  return (
    <Fragment>
      <div className="user-dashbord mb-5">
        <div className="section-header-dashbord common-btm-mrgn">
          <div className="container">
            {company && <div className="content">
              <div className="d-flex align-items-center gap-2">
              {company.logo && <img src={company.logo} alt="Company Logo" width={120} height={80} className="mr-3" />}
              <div className="mt-3 ">
              <h4 className="pt-1 mb-1">Welcome! {user.name}.</h4>
              <p className="text-secondary">
                <strong>{company.name}</strong> <strong>{company.companyCode}</strong> 
              </p>
              </div>
              </div>
            </div>}
          </div>
        </div>
        
        <section className="steps-sec">
            <div className="container">
                <ul style={{maxWidth:"250px"}} className="nav nav-tabs my-2 responsive-tab" role="tablist">
                    <li className="nav-item text-center" onClick={() => toggleMult(false)}>
                        <a   className={`files-upload one-file-upload  ${!checkMultipleFiles && "active"} `} data-bs-toggle="tab" href="#home">
                        One File
                        </a>
                    </li>
                    <li className="nav-item" onClick={() => toggleMult(true)}>
                        <a className={`files-upload  ${checkMultipleFiles && "active"} `} data-bs-toggle="tab" href="#menu1">
                        Multiple Files
                        </a>
                    </li>
                </ul>
            </div>
        </section>

        {!checkMultipleFiles?(!showProgressBar ? <div className="dashbord-three-box common-btm-mrgn">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sngl-box green-box">
                  <div className="zone">
                    <div id="dropZ">
                      <img src="/images/file.png" alt="" />
                      <div className="drag-text">
                        Drag & drop your entire document here.
                      </div>
                      <div className="drag-text">Supported file types: 
                      Images; Documents (.docx); Spreadsheets (.xlxs , .csv); PDF; Powerpoint (.pptx)</div>
                      <div className="pro-or">
                        <span> OR</span>
                      </div>

                      <DragDropUploadButton
                        btnCss={"upload-f"}
                        btnName={"Browse File"}
                        multiple={false}
                        disabled={!!blobFile}
                      />
                      <DragDropUploadButton
                        btnName={""}
                        multiple={false}
                        onChange={wordCountCheck}
                        disabled={!!blobFile}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-4">
                <div className="sngl-box red-box">
                  <h3><span><img src="/images/tickdb.svg" alt="" /></span> {cancelJobs}</h3>
                  <h5>Projects cancelled</h5>
                  <p>{cancelJobs}</p>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>: <ProgressBar className='my-5' animated striped  now={progressPercentage} label={`${progressPercentage}%`}/>):
        (!showProgressBarFiles ?
        <div className="dashbord-three-box common-btm-mrgn">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sngl-box green-box">
                  <div className="zone">
                    <div id="dropZ">
                      <img src="/images/file.png" alt="" />
                      <div className="drag-text">
                        Drag & drop your entire documents here.
                      </div>
                      <div className="drag-text">Supported file types: 
                      Images; Documents (.docx); Spreadsheets (.xlxs , .csv); PDF; Powerpoint (.pptx)</div>
                      <div className="pro-or">
                        <span> OR</span>
                      </div>

                      <DragDropUploadButton btnCss={"upload-f"} btnName={"Browse Files"} multiple={true} onChange={(files) => handleMultipleFiles(files)}  disabled={!!blobFile}/>
                      <DragDropUploadButton btnName={""} multiple={true} onChange={handleMultipleFiles} disabled={!!blobFile} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> : <ProgressBar className='my-5' animated striped  now={progressPercentageFiles} label={`${progressPercentageFiles}%`}/>)}
        

        <div className="dashbord-three-box common-btm-mrgn">
          <div className="container">
            {/* <div className="d-flex gap-3 mt-5">
              <button color="" style={buttonStyle} onClick={downloadAllUnPaidInvoices}>
                Download all unpaid Invoices
              </button>
              <button color="" style={buttonStyle} onClick={downloadAllPaidReceipts}>
                Download all Paid Receipts
              </button>
            </div> */}
          </div>
        </div>

        <div className="dashbord-three-box common-btm-mrgn">
          <div className="container">
            <TableBusiness />
          </div>
        </div>
      </div>
      {/*
        <RecentProjects gotoSelectedProject={gotoSelectedProject} />
        <RecentTranscationSlider gotoSelectedReceipt={gotoSelectedReceipt} />
      */}

      <SocialShare
        setShowSocialShare={setShowSocialShare}
        showSocialShare={showSocialShare}
      />
      {showWithDrawModel && (
        <WithDrawModelCustomer
          showWithDrawModel={showWithDrawModel}
          setShowWithDrawModel={setShowWithDrawModel}
        />
      )}
    </Fragment>
  );
};

export default BusinessDashboard;
