import React, { useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel';
import ProjectSinglecard from './SingleRecentProject';
import { getAllJobs } from '../../../services/api/jobApis';
import { clearJobs, setJobs } from '../../../redux/reducers/jobSlice';
import { useDispatch, useSelector } from 'react-redux';
import { startLoading, stopLoading } from '../../../redux/reducers/loadingSlice';
import { NavLink } from 'react-router-dom';

const RecentProjectsSlider = ({ gotoSelectedProject }) => {
    const dispatch = useDispatch()
    const { myJobs } = useSelector((state) => state.job)
    const options = {
        items: 2,
        dots: false,
        nav: true,
        loop: false,
        margin: 10,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            }
        }
    }

    console.log('myJobs ===>', myJobs);
    const getOngoingProjects = async () => {
        dispatch(startLoading())
        let data = await getAllJobs('all', 1)
        if (data?.data?.length > 0) {
            dispatch(setJobs({ jobs: data?.data, pageNumber: data?.pageNumber, totalJobCount: data?.dataCount }))
        }
        dispatch(stopLoading())
    }
    useEffect(() => {
        if (!myJobs?.length) {
            getOngoingProjects()
        }
    }, [myJobs])
    useEffect(() => {
        return () => {
            dispatch(setJobs({ jobs: null, pageNumber: 1, totalJobCount: 0 }));
        }
    }, [])
    return (
        <div className="dashbord-recent-project common-btm-mrgn-big">
            {(myJobs && myJobs?.length > 0) && <div className="container">
                <div className="section-head-two common-btm-mrgn">
                    <h4>Recent Projects (<NavLink to='/job-list'>All Jobs</NavLink>)</h4>
                </div>
                <div className="recnt-pro-wraper">
                    <OwlCarousel className='owl-carousel owl-theme' {...options}>
                        {
                            myJobs?.map((item, idx) => {
                                return (
                                    !item.checkMultipleFiles && <ProjectSinglecard key={idx} item={item} idx={idx} gotoSelectedProject={gotoSelectedProject} />
                                )
                            })
                        }
                    </OwlCarousel>
                </div>
            </div>}
        </div>
    )
}

export default RecentProjectsSlider