import React from 'react';
import './toggleswitch.css';

const ToggleSwitch = ({ id, isOn, handleToggle, onLabel = 'Yes', offLabel = 'No' }) => {
    return (
        <div className="toggle-switch">
            <input
                checked={isOn}
                onChange={handleToggle}
                className="toggle-switch-checkbox"
                id={`toggle-switch-${id}`} // unique id for each toggle
                type="checkbox"
            />
            <label className="toggle-switch-label" htmlFor={`toggle-switch-${id}`}>
                <span className="toggle-switch-inner" data-yes={onLabel} data-no={offLabel} />
                <span className="toggle-switch-switch" />
            </label>
        </div>
    );
};

export default ToggleSwitch;
