import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LanguageSelector from '../../../components/common/LanguageSelector';
import ToggleSwitch from '../../../components/common/toggleCertificate/ToggleSwitch';
import { setDeadlineOptions, setUploadedSubFiles,setActiveStep, updateJob } from '../../../redux/reducers/jobPostSlice';
import ResponsiveStepper from './ResponsiveStepper';
import { useNavigate } from 'react-router-dom'
import { getDeadlines } from '../../../services/api/jobPostApis';
import { toast } from 'react-toastify';
const TranslationConfigureFiles = ({handlePriceFiles}) => {
    const jobPostTranslationFormik = {
        values: {
            fromLang: '',
            toLang: ''
        }
    };
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { uploadedSubFiles, jobWordCount, serviceType, selectedSpeedOption, jobPrice } = useSelector((state) => state.jobPost);
    const [files, setFiles] = useState(uploadedSubFiles);
    const { user } = useSelector((state) => state.auth);
    const [errors, setErrors] = useState({});

    const calculateFilePrice = (fileWordCount, langF, langT, certified) =>{
        langF = langF ? langF.toLowerCase() : "arabic";
        langT = langT ? langT.toLowerCase() : "english";
        langF = langF.toLowerCase();
        langT = langT.toLowerCase();
        let normalLanguages = {
            arabic: true,
            english: true,
            british: true, 
            american: true,
            standard: true,
            colloquial: true,
            "american english": true,
            "british english": true,
            "standard arabic": true,
            "colloquial arabic": true,

        } 
        let initialPrice = Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1) : ((((selectedSpeedOption?.perWordPrice * fileWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * fileWordCount) * 1)).toFixed(4)));
        let middlePrice = initialPrice * 1.2;
        let finalPrice = middlePrice * 1.2;

        if(certified && (!normalLanguages[langF] || !normalLanguages[langT])){
            return finalPrice
        }
        if(certified || (!normalLanguages[langF] || !normalLanguages[langT])){
            return middlePrice
        }
        return initialPrice

    }

    const handleLanguageChange = (id, type, value) => {
        const newFiles = files.map(file => {
            if (file.id === id) {
                return { ...file, [type]: value };  
            }
            return file;
        });
        setFiles(newFiles);
        // dispatch(setUploadedSubFiles({uploadedSubFiles: newFiles}));

        setErrors(prevErrors => ({
            ...prevErrors,
            [id]: { ...prevErrors[id], [type]: false }
        }));
    };

    const handleCertificateToggle = (id) => {
        const updatedFiles = files.map(file =>
            file.id === id ? { ...file, certificate: !file.certificate } : file
        );
        // file.id === id && setFilesPrices([...filesPrices, calculateFilePrice(file.wordCount, file.fromLang, file.toLang, file.certificate)])
        setFiles(updatedFiles);
        dispatch(setUploadedSubFiles({uploadedSubFiles: updatedFiles}));
    };

    const handleCommentChange = (id, value) => {
        const newFiles = files.map(file => {
            if (file.id === id) {
                return { ...file, additionalComment: value };
            }
            return file;
        });
        setFiles(newFiles);
        dispatch(setUploadedSubFiles({uploadedSubFiles: newFiles}));
    };
    const allLanguagesSelected = files.every(file => file.fromLang && file.toLang);
    
    const gotoNext = async () => {
        
        const newFiles = files.map(file => ({
            ...file,
            filePrice: calculateFilePrice(file.wordCount, file.fromLang, file.toLang, file.certificate),
            initialPrice : (Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1) : ((((selectedSpeedOption?.perWordPrice * file.wordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * file.wordCount) * 1)).toFixed(4))))
        }));
    
        setFiles(newFiles);
        dispatch(setUploadedSubFiles({uploadedSubFiles: newFiles}));

        
        if (!allLanguagesSelected) {
            toast.error('Please select both "From" and "To" languages for all files.');
            return;
        }
       
        let deadLineValues = {
            wordCount: Number(jobWordCount),
            serviceType: serviceType
        
         }
          let data = user.role === "company" ? await getDeadlines(deadLineValues) : await getDeadlines(deadLineValues)
          if (typeof data?.result?.data?.speedOptions !== 'string' && data?.result?.data?.speedOptions?.length){

              dispatch(setDeadlineOptions({ deadlineOptions: data?.result?.data?.speedOptions }))
              let values = {
                addComments :"test comments",
                chooseTranslator: "General Translator",
                fromLang: "Arabic",
                showWatermark: false,
                specialisation: "",
                toLang:"English"
              }
              dispatch(
                  (dispatch) =>
                    new Promise((resolve) => {
                      dispatch(
                        updateJob({
                          type: 'UPDATE_TRANSLATION_CONFIGURATION',
                          value: { ...values },
                        })
                      );
                      resolve();
                    })
                );

                dispatch(setActiveStep({ activeStep: 'chooseDeadline' }))
                navigate('/job-post/choose-deadline')
          }else {
            toast.error("No deadline available for this file")
        }
           
    }
    const goPreviousPage = () => {
        dispatch(setActiveStep({ activeStep: 'serviceType' }))
        navigate('/job-post/service-type')
    }
    useEffect(() => {
        const newFiles = files.map(file => ({
            ...file,
            filePriceConfig: calculateFilePrice(file.wordCount, file.fromLang, file.toLang, file.certificate),
            initialPriceConfig : Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1) : ((((selectedSpeedOption?.perWordPrice * file.wordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * file.wordCount) * 1)).toFixed(4)))
        }));
        const { priceAllFiles, allInitialPrice } = newFiles.reduce(
            (totals, file) => {
                totals.priceAllFiles += file.filePriceConfig || 0; 
                totals.allInitialPrice += file.initialPriceConfig || 0; 
                return totals;
            },
            { priceAllFiles: 0, allInitialPrice: 0 } 
        );
        handlePriceFiles(jobPrice + (priceAllFiles-allInitialPrice))
    }, [files]);

    return (
        <>
        <ResponsiveStepper goNext={gotoNext} goPrevious={goPreviousPage} />
        <div className="file-translation-table">
            {/* <h3>Translate Multiple Files</h3> */}
            <table className="text-gray-500 dark:text-gray-400 w-full text-left text-sm mt-4">
                <thead className="text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400 text-xs uppercase">
                    <tr className="mt-4">
                        <th scope="col" className="ps-1">No.</th>
                        <th scope="col" className="ps-2" style={{width:'50px'}}>File Name</th>
                        <th scope="col" className="ps-4">Translate From</th>
                        <th scope="col" className="px-2">Translate To</th>
                        <th scope="col" className="" style={{width:'150px'}}>Certified Translation?</th>
                        <th scope="col" className="px-2">Additional Comments</th>
                    </tr>
                </thead>
                <tbody>
                    {uploadedSubFiles.map((file) => (
                        <tr key={file.id} className="mt-4 dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 border-b bg-white">
                            <th scope="row" className="text-gray-900 whitespace-nowrap ps-1 font-medium dark:text-white">
                                {file.id}
                            </th>
                            <td className="ps-2 mb-4" style={{maxWidth: "140px",whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis" }}  title={file.name}>{file.name}</td>
                            <td  className={errors[file.id]?.toLang ? 'border-red-500 ps-4' : 'ps-4'}>
                                <LanguageSelector
                                    title=""
                                    getLanguage={(value) => handleLanguageChange(file.id, 'fromLang', value)}
                                    jobPostTranslationFormik={jobPostTranslationFormik}
                                    langFiles="fromLang"
                                    value={file.fromLang} 
                                />
                            </td>
                            <td className="px-2">
                                <LanguageSelector
                                    title=""
                                    getLanguage={(value) => handleLanguageChange(file.id, 'toLang', value)}
                                    jobPostTranslationFormik={jobPostTranslationFormik}
                                    langFiles="toLang"
                                    value={file.toLang} 
                                />
                            </td>
                            <td className="pt-3  text-center">
                                <ToggleSwitch
                                
                                    id={file.id}
                                    isOn={file.certificate}
                                    handleToggle={() => handleCertificateToggle(file.id)}
                                />
                            </td>
                            <td className="px-2">
                                <textarea
                                    className="w-full  py-1 border rounded-md"
                                    value={file.additionalComment}
                                    onChange={(e) => handleCommentChange(file.id, e.target.value)}
                                    placeholder="Add any specific requests..."
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

        </div>

        <div className="button-set">
            <div onClick={goPreviousPage} className="Previous-button  text-center mt-1 ml-2 btn btn-border-black">Previous
            </div>
            <div onClick={gotoNext} className="next-button text-center mt-1 ml-2 btn">Next
            </div>
        </div>
        </>
    );
};

export default TranslationConfigureFiles;

